<template>
  <div class="hello">
    <h1>Contact Us</h1>
    <p>
      If you have any questions or need support, send us an email at 
      <a class="link" target="_blank" href="mailto:contact@wewake.io">contact@wewake.io</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  created() {
    window.location.href = "mailto:contact@wewake.io";
  }
};
</script>

<style scoped>


.link{
  font-weight: 400;
  text-decoration: none;
  color: black;
}

.link:hover{
  color: #4CD964;

}
</style>